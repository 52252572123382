import { assert } from "./assert";

export const IS_SAFARI = /apple/i.test(navigator.vendor);

export function setShown(el: string|HTMLElement|HTMLElement[], show: boolean) {
  if (typeof el === 'string' || el instanceof String) {
    const realEl = getById(el as string);
    assert(realEl, 'Unable to find element with id '+ el);
    setShown(realEl, show);
    return;
  }
  if (el instanceof Array) {
    for (const e of el) {
      setShown(e, show);
    }
  } else {
    (el as HTMLElement).style.display = show ? '' : 'none';
  }
}

export function isShown(el: HTMLElement): boolean {
  return el.style.display !== 'none';
}

export function querySelectorAll<T extends HTMLElement>(query: string): T[] {
  return Array.from(document.querySelectorAll(query)) as T[];
}

export function querySelector<T extends HTMLElement>(query: string): T {
  return document.querySelector(query) as T;
}

export function getById<T extends HTMLElement>(id: string): T {
  return document.getElementById(id) as T;
}

/**
 * Robot in disguise.
 */
export class Transformer {
  constructor(private readonly el: HTMLElement,
        private _x: number,
        private _y: number) {}

  translate(x: number, y: number) {
    if (this._x === x && this._y === y) {
      return;
    }
    this._x = x;
    this._y = y;
    this.updateTransform();
  }

  set x(x: number) {
    if (this._x === x) {
      return;
    }
    this._x = x;
    this.updateTransform();
  }

  get x() {
    return this._x;
  }

  set y(y: number) {
    if (this._y === y) {
      return;
    }
    this._y = y;
    this.updateTransform();
  }

  get y() {
    return this._y;
  }

  private updateTransform() {
    this.el.style.transform = `translate3d(${this._x}px,${this._y}px,0)`;
  }
}
