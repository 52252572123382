import { assert } from '../util/assert';
import * as Handlebars from 'handlebars';
import { getKmzImageUrl, Destination } from "../util/kmz-data";
import { getById, querySelector, setShown } from "../util/dom";
import { isSet, roundToDecimal } from "../util/types";
import { CURRENT_MILE_UNKNOWN, StateUpdate } from "./state";
import { View, ViewType } from "./view";
import { ScrollIndicator } from './scroll_indicator';

const HOURS_PER_MS = 60 * 60 * 1000;

interface SpotRef {
  metadata: Destination;
  el: HTMLElement;
  etaEl: HTMLElement;
  distanceEl: HTMLElement;
  shown: boolean;
  scrollPos: number;
}

export class RouteView extends View {
  private prevScrollY: number|null = null;
  private windowWidth: number|null = null;
  private spots: SpotRef[];
  private shownSpots: SpotRef[];
  private scrollIndicator: ScrollIndicator;
  private windowHeight: number;

  private mileMarkerIsTraveled = true;

  getType(): ViewType {
    return ViewType.ROUTE;
  }

  protected render() {
    this.createIconStyles();

    // Render spots.
    const source = getById('spots-template').innerHTML;
    const template = Handlebars.compile(source);
    const kmzSpots = this.state.kmz.spots;
    const spotsEl = getById('spots');
    spotsEl.innerHTML = template({spots: kmzSpots});

    // Init spots array.
    this.spots = [];
    for (let i = 0; i < kmzSpots.length; i++) {
      const el = spotsEl.children[i];
      this.spots.push({
        metadata: kmzSpots[i],
        el: (el as HTMLElement),
        etaEl: assert(el.querySelector('.eta-val') as HTMLElement),
        distanceEl: assert(el.querySelector('.distance-num') as HTMLElement),
        shown: i !== 0,
        scrollPos: 0, // populated by handleResize
      });
    }
    setShown(spotsEl.children[0] as HTMLElement, false);
    // Note: we remove the first item here because the first spot is always the
    // starting point of the route, and that's not very helpful.
    this.shownSpots = this.spots.slice(1);

    getById('hamburger').addEventListener('click',
        () => this.navigate(ViewType.MENU));
    getById('scroll-to-location').addEventListener('click', () => {
      const scrollY = this.getCurrentLocationScroll();
      if (scrollY !== null) {
        window.scrollTo(0, scrollY);
      }
    });
    getById('mile-marker').addEventListener('click',
        () => this.toggleMileMarkerMode());

    window.addEventListener('resize', () => this.handleResize({}));
    this.scrollIndicator =
        new ScrollIndicator(this.getScrollIndicatorText.bind(this));
  }

  protected renderUpdates(stateUpdate: StateUpdate): number {
    this.renderStateUpdate(stateUpdate);

    // If we have the location on initialize scroll there.
    // If the visible categories just changed, the page resized so we should
    // just scroll to the current location.
    if (this.state.currentMile !== CURRENT_MILE_UNKNOWN &&
        stateUpdate.visibleCategories) {
      return this.getCurrentLocationScroll() ?? this.prevScrollY;
    } else {
      return this.prevScrollY;
    }
  }

  protected onPerformDomCalculations(stateUpdate: StateUpdate) {
    this.handleResize(stateUpdate, true);
  }

  protected onActive(stateUpdate: StateUpdate) {
    this.toggleScrollIndicatorFromLocation();
  }

  protected onActiveStateUpdate(stateUpdate: StateUpdate) {
    this.renderStateUpdate(stateUpdate);
    this.handleResize(stateUpdate);
    this.toggleScrollIndicatorFromLocation();
  }

  private renderStateUpdate(stateUpdate: StateUpdate) {
    if (isSet(stateUpdate.isRouteReversed)) {
      const spotsEl = getById('spots');
      for (let i = 1; i < spotsEl.childNodes.length; i++){
        spotsEl.insertBefore(spotsEl.childNodes[i], spotsEl.firstChild);
      }
      this.spots.reverse();
      this.shownSpots.reverse();
      // Always hide the first one.
      setSpotShown(this.spots[0], false);
      setSpotShown(this.spots[this.spots.length - 1],
          this.state.visibleCategories.has(this.spots[this.spots.length - 1].metadata.categoryName));
    }

    if (isSet(stateUpdate.visibleCategories)) {
      this.toggleVisibleCategories();
    }
    if (isSet(stateUpdate.locationUpdating)) {
      this.toggleLocationUpdating();
    }
    // Changing categories affects the currentLocation line and distances
    // are only set on visible spots.
    if (isSet(stateUpdate.isRouteReversed) ||
        isSet(stateUpdate.visibleCategories) ||
        isSet(stateUpdate.currentMile) ||
        isSet(stateUpdate.speed)) {
      this.handleUpdatedDistance();
    }
  }

  private toggleScrollIndicatorFromLocation() {
    if (this.state.currentMile === CURRENT_MILE_UNKNOWN) {
      this.scrollIndicator.deactivate();
    } else {
      this.scrollIndicator.activate();
    }
  }

  protected onDeactivate() {
    this.prevScrollY = window.scrollY;
    this.scrollIndicator.deactivate();
  }

  private createIconStyles() {
    const kmz = this.state.kmz;
    const el = document.createElement('style');
    document.head.appendChild(el);
    for (const iconId in Object.values(kmz.icons)) {
      const iconPath = kmz.icons[iconId];
      el.sheet.insertRule(`.icon-${iconId}{
        background-image: url(${getKmzImageUrl(iconPath)});
      }`, 0);
    }
  }

  private getCurrentLocationScroll(): number|null {
    const currentLocationEl =
        document.querySelector('.current-location-line.shown');
    if (!currentLocationEl) {
      return null;
    }
    return currentLocationEl.parentElement.offsetTop - 130;
  }

  private handleResize(stateUpdate: StateUpdate, force = false) {
    if (!this.isActive && !force) {
      return;
    }
    this.windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    if (windowWidth === this.windowWidth &&
        !isSet(stateUpdate.visibleCategories) &&
        !isSet(stateUpdate.isRouteReversed)) {
      return;
    }
    this.windowWidth = windowWidth;
    for (const spot of this.shownSpots) {
      spot.scrollPos = spot.el.offsetTop;
    }
  }

  private getScrollIndicatorText(scrollY: number): string|null {
    // Find the distance.
    const spot = findClosest(this.shownSpots,
                             scrollY + (this.windowHeight / 2));
    if (!spot) {
      // Not sure why this can happen.
      return null;
    }
    const distance = spot.metadata.mile - this.state.currentMile;
    return (distance > 0 ? '+' : '') + Math.round(distance);
  }

  private toggleLocationUpdating() {
    setShown(querySelector('#route .location-pending'), this.state.locationUpdating);
  }

  /*
   * This must be called before handleUpdatedDistance() because it populates
   * visibleSpots.
   */
  private toggleVisibleCategories() {
    const visibleCategories = this.state.visibleCategories;

    for (let i = 1; i < this.spots.length; i++) {
      const spot = this.spots[i];
      setSpotShown(spot, visibleCategories.has(spot.metadata.categoryName));
    }
    this.shownSpots = this.spots.filter(spot => spot.shown);
  }

  private handleUpdatedDistance() {
    if (this.state.currentMile === CURRENT_MILE_UNKNOWN) {
      return;
    }
    let placedLocationLine = false;
    for (const spot of this.spots) {
      if (!spot.shown) {
        continue;
      }
      const distance = spot.metadata.mile - this.state.currentMile;
      spot.distanceEl.textContent = roundToDecimal(distance, 1);
      const milesFromExit = spot.metadata.milesFromExit || 0;
      spot.etaEl.textContent = this.calculateEta(Math.abs(distance) + milesFromExit);
      if (!placedLocationLine && distance >= 0) {
        placeLocationLine(spot.el);
        placedLocationLine = true;
      }
    }
    this.updateMileMarker();
  }

  private calculateEta(distance: number): string {
    const hours = distance / this.state.speed;
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    let text = '';
    if (wholeHours > 0) {
      text = wholeHours + 'h ';
    }
    text += minutes + 'm';

    const eta = new Date(Date.now() + (hours * HOURS_PER_MS));
    const now = new Date();
    // Only show time of arrival if its today.
    if (eta.getDate() === now.getDate()) {
      let time = eta.toLocaleTimeString();
      // remove the seconds.
      // example format 1:15:30 AM
      let splitBySpace = time.split(' ');
      const sections = splitBySpace[0].split(':');
      splitBySpace[0] = sections[0] + ':' + sections[1];
      time = splitBySpace.join(' ');
      text += ' (' + time + ')';
    }
    return text;
  }

  private toggleMileMarkerMode() {
    this.mileMarkerIsTraveled = !this.mileMarkerIsTraveled;
    const labelEl = getById('mile-marker-label');
    if (this.mileMarkerIsTraveled) {
      labelEl.textContent = 'Miles traveled:';
    } else {
      labelEl.textContent = 'Miles to go:';
    }
    this.updateMileMarker();
  }

  private updateMileMarker() {
    const value = this.mileMarkerIsTraveled ? this.state.currentMile :
        this.state.kmz.totalDistance - this.state.currentMile;
    querySelector('#route .mile-marker-num').textContent =
        roundToDecimal(value, 1);
  }
}

function placeLocationLine(spotEl: HTMLElement) {
  const currentEl = document.querySelector('.current-location-line.shown');
  if (currentEl) {
    currentEl.classList.remove('shown');
  }
  spotEl.querySelector('.current-location-line').classList.add('shown');
}

// Returns the index of the closest value.
function findClosest(arr: SpotRef[], target: number): SpotRef {
  let upper = arr.length - 1;
  let lower = 0;
  while (lower <= upper) {
    const mid = Math.floor((lower + upper) / 2);

    if (arr[mid].scrollPos === target) {
      return arr[mid];
    }

    if (arr[mid].scrollPos < target) {
      lower = mid + 1;
    } else {
      upper = mid - 1;
    }
  }
  return arr[upper];
}

function setSpotShown(spot: SpotRef, shown: boolean) {
  spot.shown = shown;
  setShown(spot.el, shown);
}