import { AventEmitter, AventType } from "../util/avent";
import { setShown } from "../util/dom";
import { State, StateAvent, StateUpdate } from "./state";

export enum ViewType {
  ROUTE,
  MENU,
}

export interface ViewAventData {
  // Set when the NavigateViews event is fired.
  newView?: ViewType;
}

export abstract class View extends AventEmitter<ViewAventData> {
  // True when View is the current view.
  private _isActive = false;
  private _isInitialized = false;
  private ignoreNextStateUpdate = false;
  private queuedStateUpdate: StateUpdate|null = null;

  constructor(protected readonly state: State,
              protected readonly requestLocationUpdate: () => void,
              readonly el: HTMLElement,
              startActive: boolean) {
    super();
    state.addAventListener(AventType.StateUpdate,
        (avent: StateAvent) => this.handleStateUpdate(avent));
    setShown(el, true);
    this.render();
    if (startActive) {
      this.queuedStateUpdate = this.state.stateProperties;
      this.performDomCalculations();
      this.activate();
    } else {
      setShown(el, false);
    }
    this._isInitialized = true;
  }

  deactivate() {
    this.onDeactivate();
    this._isActive = false;
  }

  hide() {
    setShown(this.el, false);
  }

  /**
   * @returns The scrollY position that should be used for the view.
   */
  reRender(): number {
    setShown(this.el, true);
    return this.renderUpdates(this.queuedStateUpdate || {});
  }

  activate() {
    setShown(this.el, true);
    this._isActive = true;
    this.onActive(this.queuedStateUpdate || {});
    this.queuedStateUpdate = null;
  }

  performDomCalculations() {
    this.onPerformDomCalculations(this.queuedStateUpdate || {});
  }

  protected navigate(newView: ViewType) {
    this.emit(AventType.NavigateViews, { newView })
  }

  abstract getType(): ViewType;

  protected abstract render();

  /**
   * Called before onActivate is called. Should update any DOM from the state,
   * but not initialize event listeners.
   * @returns The scrollY position that should be scrolled to.
   */
  protected abstract renderUpdates(stateUpdate: StateUpdate): number;

  /**
   * Perform any CPU intensive calculations. This is called during the
   * transition animation. After renderUpdates, before onActive.
   */
  protected onPerformDomCalculations(stateUpdate: StateUpdate) {}

  protected abstract onActive(stateUpdate: StateUpdate);

  protected abstract onDeactivate();

  protected abstract onActiveStateUpdate(avent: StateUpdate);

  protected updateState(stateUpdate: StateUpdate, skipOwnViewUpdate: boolean) {
    this.ignoreNextStateUpdate = true;
    this.state.update(stateUpdate);
    this.ignoreNextStateUpdate = false;
  }

  private handleStateUpdate(avent: StateAvent) {
    if (this.ignoreNextStateUpdate) {
      this.ignoreNextStateUpdate = false;
      return;
    }
    if (this.isActive) {
      this.onActiveStateUpdate(avent.data);
    } else {
      this.queuedStateUpdate = avent.data;
    }
  }

  get isActive() {
    return this._isActive;
  }

  get isInitialized() {
    return this._isInitialized;
  }
}
