/**
 * Global state that is shared across views.
 */

import { KmzData, reverseRoute } from "../util/kmz-data";
import { Avent, AventEmitter, AventType } from "../util/avent";
import { areSetsEqual, isObjectEmpty, isSet } from "../util/types";
import { getFromStorage, saveToStorage, StorageKey } from "../util/local-storage";

export const CURRENT_MILE_UNKNOWN = -1;

interface StateProperties {
  // Estimated speed to use when showing time estimates.
  speed: number,
  currentMile: number,
  visibleCategories: Set<string>,
  locationUpdating: boolean;
  // Empty string is used when the location was never set.
  locationLastUpdated: string;
  locationAccuracy: string;
  isRouteReversed: boolean;
}

export type StateUpdate = Partial<StateProperties>;

export type StateAvent = Avent<StateUpdate>;

export class State extends AventEmitter<StateUpdate> {
  // Unlike the other properties kmz is immutable.
  readonly kmz: KmzData;
  private _stateProperties: StateProperties;

  constructor(kmz: KmzData,
              currentMile: number,
              locationUpdating: boolean,
              locationLastUpdated: string,
              locationAccuracy: string,) {

    super();
    this.kmz = kmz;
    
    const speed = getFromStorage(StorageKey.SPEED);
    const isRouteReversed = getFromStorage(StorageKey.REVERSE_ROUTE);

    if (isRouteReversed) {
      reverseRoute(kmz);
      currentMile = kmz.totalDistance - currentMile;
    }

    // Init visibleCategories with all categories.
    const visibleCategories = new Set<string>();
    for (const category of kmz.categories) {
      visibleCategories.add(category.name);
    }

    this._stateProperties = {
      currentMile,
      visibleCategories,
      speed,
      locationUpdating,
      locationLastUpdated,
      locationAccuracy,
      isRouteReversed,
    };
  }

  update(newState: StateUpdate) {
    let stateUpdate = {} as StateUpdate;
    // Only set properties from newState that are actually different from the current state.
    for (let prop in newState) {
      if (isSet(newState[prop]) && !isEqual(newState[prop], this._stateProperties[prop])) {
        this._stateProperties[prop] = newState[prop];
        stateUpdate[prop] = newState[prop];
      }
    }
    if (isObjectEmpty(stateUpdate)) {
      return;
    }
    if (isSet(stateUpdate.speed)) {
      saveToStorage(StorageKey.SPEED, stateUpdate.speed);
    }
    if (isSet(stateUpdate.isRouteReversed)) {
      saveToStorage(StorageKey.REVERSE_ROUTE, stateUpdate.isRouteReversed);
      reverseRoute(this.kmz);
      stateUpdate.currentMile = this.kmz.totalDistance - (stateUpdate.currentMile ?? this._stateProperties.currentMile);
      this._stateProperties.currentMile = stateUpdate.currentMile
    }

    this.emit(AventType.StateUpdate, stateUpdate);
  }

  get stateProperties(): StateProperties {
    return this._stateProperties;
  }
  get speed(): number {
    return this._stateProperties.speed;
  }
  get currentMile(): number {
    return this._stateProperties.currentMile;
  }
  get visibleCategories(): Set<string> {
    return this._stateProperties.visibleCategories;
  }
  get locationUpdating(): boolean {
    return this._stateProperties.locationUpdating;
  }
  get locationLastUpdated(): string {
    return this._stateProperties.locationLastUpdated;
  }
  get locationAccuracy(): string {
    return this._stateProperties.locationAccuracy;
  }
  get isRouteReversed(): boolean {
    return this._stateProperties.isRouteReversed;
  }
}

// Equality check that works for any of the StateProperties.
function isEqual<T>(a: T, b: T): boolean {
  if (a instanceof Set && b instanceof Set) {
    return areSetsEqual(a, b);
  }
  return a === b;
}