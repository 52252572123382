import * as Handlebars from 'handlebars';
import { Destination } from "./util/kmz-data";
import { roundToDecimal } from "./util/types";

Handlebars.registerHelper('destination-map-url', (dest: Destination) => {
  const enc = encodeURIComponent;
  const coordString = enc(dest.coord.lat) + ',' + enc(dest.coord.lon);
  const placeIdParam = dest.googlePlaceId ?
      '&query_place_id=' + enc(dest.googlePlaceId) : '';
  return new Handlebars.SafeString(
      `https://www.google.com/maps/search/?api=1&query=${coordString}${placeIdParam}`);
});

Handlebars.registerHelper('exit-map-url', (dest: Destination) => {
  if (!dest.exitCoord) {
    return '#';
  }
  const enc = encodeURIComponent;
  const coordString = enc(dest.exitCoord.lat) + ',' + enc(dest.exitCoord.lon);
  return new Handlebars.SafeString(
      `https://www.google.com/maps/search/?api=1&query=${coordString}`);
});

Handlebars.registerHelper('round-to-decimal', (miles: number) => {
  return roundToDecimal(miles);
});
