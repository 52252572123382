export function isSet<T>(num: T|undefined|null): num is T {
  return num !== undefined && num !== null;
}

export function areSetsEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
  if (set1.size !== set2.size) return false;
  for (let value of set1) {
    if (!set2.has(value)) {
      return false;
    }
  }
  return true;
}

export function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export function isObjectEmpty(obj: Object): boolean {
  for (let i in obj) {
    return false;
  }
  return true;
}

// Example: roundToDecimal(12.36, 1) -> '12.4'
export function roundToDecimal(num: number, places: number = 1): string {
  const str = String(Math.round(num * Math.pow(10,places)));
  if (str.length === 1) {
    return '0.' + str;
  }
  return str.substring(0, str.length - places) + '.' + str.substring(str.length - places);
}
