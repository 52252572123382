import * as Handlebars from 'handlebars';
import { roundToDecimal } from '../util/types';
import { getById, querySelector, querySelectorAll, setShown } from "../util/dom";
import { CURRENT_MILE_UNKNOWN, StateUpdate } from "./state";
import { View, ViewType } from "./view";
import { DEFAULT_SPEED } from '../util/local-storage';

export class MenuView extends View {
  getType(): ViewType {
    return ViewType.MENU;
  }

  protected render() {
    // Update route map link.
    const routeMapLink = getById('route-map-link');
    setShown(routeMapLink, false);
    if (this.state.kmz.link) {
      const kmzUrl = new URL(this.state.kmz.link);
      const mid = kmzUrl.searchParams.get('mid');
      if (mid) {
        routeMapLink.querySelector('a')!.href =
            `https://www.google.com/maps/d/viewer?mid=${encodeURIComponent(mid)}`;
        setShown(routeMapLink, true);
      }
    }

    const source   = getById('category-selector-template').innerHTML;
    const template = Handlebars.compile(source);
    getById('category-selector').innerHTML =
        template({categories: this.state.kmz.categories});

    this.selectAllCheckbox.addEventListener(
        'change', () => this.handleCategorySelectAllToggle());
    for (const checkbox of this.categoryCheckboxes) {
      checkbox.addEventListener('change', (e) => this.handleCategoryCheckboxToggle());
    }

    this.renderKmzData();

    getById('update-location').addEventListener('click', () => this.requestLocationUpdate());
    getById('close').addEventListener('click', () => {
      getById('close').classList.add('spin');
      requestAnimationFrame(() => this.navigate(ViewType.ROUTE));
    });
  }

  private renderKmzData() {
    const kmz = this.state.kmz;
    getById('kmz-name').textContent = kmz.name;
    getById('kmz-total-distance').textContent =
        roundToDecimal(kmz.totalDistance, 1);

    const reverseRouteCheckbox = getById('reverse-route') as HTMLInputElement;
    reverseRouteCheckbox.checked = Boolean(this.state.isRouteReversed);

    const hasKmzLink = !!kmz.link;
    setShown('refresh-kmz-link', hasKmzLink);
    setShown('share-kmz-link', hasKmzLink);
    if (hasKmzLink) {
      if (navigator.share) {
        const button = querySelector('#share-kmz-link button');
        setShown(button, true);
        button.addEventListener('click', () => {
          navigator.share({ url: getShareUrl(kmz.link, kmz.name) })
        });
      } else {
        const link = querySelector('#share-kmz-link a');
        setShown(link, true);
        link.setAttribute('href', getShareUrl(kmz.link, kmz.name));
      }
    }
  }

  protected renderUpdates(stateUpdate: StateUpdate): number {
    this.onActiveStateUpdate(stateUpdate);
    getById('close').classList.remove('spin');
    return 0;
  }

  protected onActive(stateUpdate: StateUpdate) {}

  protected onActiveStateUpdate(avent: StateUpdate) {
    // It's so innexpensive to update all of the properties in menu that we
    // ignore the actual updated properties.
    const state = this.state;
    getById<HTMLInputElement>('speed').value = state.speed + '';
    getById('loc-last-updated').textContent = state.locationLastUpdated;
    getById('loc-accuracy').textContent = state.locationAccuracy;
    setShown(querySelector('#menu .location-pending'), state.locationUpdating);
    if (this.state.currentMile !== CURRENT_MILE_UNKNOWN) {
      querySelector('#menu .mile-marker-num').textContent =
          roundToDecimal(this.state.currentMile, 1);
    }
  }

  protected onDeactivate() {
    const checkboxes = querySelectorAll<HTMLInputElement>('#category-selector input[data-name]');
    const visibleCategories = new Set<string>();
    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        visibleCategories.add(checkbox.dataset.name);
      }
    }
    const speed = readInputNumber(getById('speed'), DEFAULT_SPEED);
    const reverseRouteCheckbox = getById('reverse-route') as HTMLInputElement;
    const isRouteReversed = Boolean(reverseRouteCheckbox.checked);
    this.updateState({visibleCategories, speed, isRouteReversed}, true /* skipOwnViewUpdate */);
  }

  private handleCategorySelectAllToggle() {
    const selectAll = this.selectAllCheckbox;
    selectAll.indeterminate = false;
    const checked = selectAll.checked;
    for (const category of this.categoryCheckboxes) {
      category.checked = checked;
    }
    this.updateNumCategoriesSelected();
  }

  private handleCategoryCheckboxToggle() {
    const numChecked = this.updateNumCategoriesSelected();
    const selectAll = this.selectAllCheckbox;
    if (numChecked === 0) {
      selectAll.checked = false;
      selectAll.indeterminate = false;
    } else if (numChecked === this.categoryCheckboxes.length) {
      selectAll.checked = true;
      selectAll.indeterminate = false;
    } else {
      selectAll.checked = false;
      selectAll.indeterminate = true;
    }
  }

  private updateNumCategoriesSelected(): number {
    const categories = this.categoryCheckboxes;
    const numChecked = categories.filter(cat => cat.checked).length;
    querySelector('label[for="select-all-checkbox"]').textContent =
        `${numChecked} selected`;
    return numChecked;
  }

  private get selectAllCheckbox(): HTMLInputElement {
    return getById('select-all-checkbox');
  }

  private get categoryCheckboxes(): HTMLInputElement[] {
    return Array.from(querySelectorAll('#category-selector input[data-name]'));
  }
}

function readInputNumber(el: HTMLInputElement, defaultVal: number) {
  const val = Number(el.value);
  if (val <= 0 || isNaN(val)) {
    return defaultVal;
  }
  return val;
}

function getShareUrl(mapUrl: string, name: string): string {
  return `${location.origin}/import-kmz.html#url=${
    encodeURIComponent(mapUrl)}&name=${encodeURIComponent(name)}`;
}
