import * as Handlebars from 'handlebars';

export const IMAGE_URL_PREFIX = '/kmzdata/0/';

export function getKmzImageUrl(path: string) {
  if (path.startsWith('https://')) {
    return path;
}
  return IMAGE_URL_PREFIX + path;
}

// Stored as 1 big array to save space.
// RouteArray is triples of (lat, lon, mile).
// Eg: [lat0, lon0, mile0, lat1, lat1, mile1, lat2...]
export type RouteArray = number[];

export interface Coordinate {
  lat: number,
  lon: number
}

export interface Category {
  name: string;
  iconId: string;
}

export interface Destination {
  name: string;
  isRouteStep: boolean;
  categoryName: string;
  description: string|Handlebars.SafeString;
  iconId: string;
  coord: Coordinate;
  mile: number;
  // The location that you leave the route to get to the destination.
  exitCoord?: Coordinate;
  milesFromExit?: number;
  googlePlaceId?: string;
  permanentlyClosed?: boolean;
}

export interface KmzData {
  // Map of id to file path.
  icons: { [id: string]: string };
  route: RouteArray;
  // In miles.
  totalDistance: number;
  spots: Destination[];
  categories: Category[];
  name: string;
  // URL of the map. Only set when the KMZ is generated to include network link.
  link?: string;
}

/**
 * Flip everything in the route as if it was created to run the other direction.
 */
export function reverseRoute(data: KmzData) {
  const totalDistance = data.totalDistance;

  const newRouteArray: RouteArray = new Array(data.route.length);
  const numEntries = data.route.length / 3;
  for (let n = 0; n < numEntries; n++) {
    const i = n * 3;
    const oldI = data.route.length - (n + 1) * 3;
    // latitude
    newRouteArray[i] = data.route[oldI];
    // longitude
    newRouteArray[i + 1] = data.route[oldI + 1];
    // mile marker
    newRouteArray[i + 2] = totalDistance - data.route[oldI + 2];
  }
  data.route = newRouteArray;

  for (const spot of data.spots) {
    spot.mile = totalDistance - spot.mile;
  }
  data.spots.reverse();
}