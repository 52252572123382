import { getById, setShown, Transformer, IS_SAFARI } from "../util/dom";

const TOP_BUFFER = 75;
const INDICATOR_HEIGHT = 70;
const HIDE_TIME = 1300;

type AnimationName = 'indicator-disappear'|'indicator-appear-from-above'|'indicator-appear-from-below'|'indicator-touch-out'|'indicator-touch-in';

export class ScrollIndicator {
  private el = getById('scroll-indicator');
  private containerEl = getById('scroll-indicator-container');
  private transformer = new Transformer(this.containerEl, 0, 0);
  private activatePending = false;
  private active = true;
  private shown = false;
  private scrollUpdateRenderQueued = false;
  private scrollIndicatorAutohideTimer: any;
  private touchMoveY: number|null = null;
  private touchStartY: number|null = null;
  private touchStartElTop: number|null = null;
  private lastScrollY = window.scrollY;

  constructor(private readonly getTextContent: (y: number) => string|null) {
    if (!this.enabled) {
      this.deactivate();
      return;
    }
    document.addEventListener('scroll', () => {
      if (this.touchStartY === null &&
          !this.scrollUpdateRenderQueued &&
          this.active) {
        this.scrollUpdateRenderQueued = true;
        requestAnimationFrame(() => {
          this.show();
        });
      }
    }, { passive: true });

    this.el.addEventListener('touchstart', this.handleTouchStart.bind(this),
                             { passive: true });
    this.el.addEventListener('touchmove', (ev: TouchEvent) => {
      ev.preventDefault();
      const hadMoveQueued = !!this.touchMoveY;
      this.touchMoveY = ev.touches[0].screenY;
      if (!hadMoveQueued) {
        requestAnimationFrame(() => {
          this.handleTouchMove();
        });
      }
    }, { passive: false });
    this.el.addEventListener('touchend', this.handleTouchEnd.bind(this),
                             { passive: true });
    this.el.addEventListener('animationend', (ev: AnimationEvent) => {
      if (ev.animationName === 'indicator-disappear') {
        this.transformer.y = -10000;
      }
    });
    this.deactivate();
  }

  /**
   * Very crude test for Safari browser.
   * This is because the scroll indicator doesn't work on iOS Safari.
   */
  get enabled() {
    return !IS_SAFARI;
  }

  activate() {
    if (!this.enabled) return;
    // Wait a frame before activating so that any changes to the page stabalize.
    this.activatePending = true;
    requestAnimationFrame(() => {
      if (!this.activatePending) {
        return;
      }
      this.activatePending = false;
      this.active = true;
      setShown(this.containerEl, true);
      this.transformer.y = -10000;
    });
  }

  deactivate() {
    if (!this.enabled) return;
    setShown(this.containerEl, false);
    this.cancelAutohideTimer();
    this.activatePending = false;
    this.active = false;
  }

  private show() {
    this.scrollUpdateRenderQueued = false;
    if (!this.activate) {
      return;
    }
    const scrollY = window.scrollY;

    if (!this.shown) {
      const diff = scrollY - this.lastScrollY;
      const animationName =
          diff > 0 ? 'indicator-appear-from-above' : 'indicator-appear-from-below';
      this.animate(animationName, '0.3s');
    }

    // Position indicator.
    const scrollPercent = scrollY / (document.documentElement.scrollHeight - window.innerHeight);
    const newY = Math.round((window.innerHeight - TOP_BUFFER - INDICATOR_HEIGHT) * scrollPercent + TOP_BUFFER);
    this.transformer.y = newY;

    this.updateTextContent(scrollY);

    this.startAutohideTimer();
    this.lastScrollY = scrollY;
    this.shown = true;
  }

  private handleTouchStart(ev: TouchEvent) {
    this.cancelAutohideTimer();
    this.touchStartY = ev.touches[0].screenY;
    this.touchStartElTop = this.transformer.y;
    this.animate('indicator-touch-out', '0.2s');
  }

  private handleTouchMove() {
    if (!this.active || this.touchStartY === null || this.touchMoveY === null) {
      // handleTouchEnd was called while the move was queued.
      return;
    }
    const diff = this.touchStartY - this.touchMoveY;
    let newY = this.touchStartElTop - diff;
    newY = Math.max(TOP_BUFFER, newY);
    newY = Math.min(newY, window.innerHeight - INDICATOR_HEIGHT);
    this.transformer.y = newY;

    const scrollPercent = (newY - TOP_BUFFER) / (window.innerHeight - TOP_BUFFER - INDICATOR_HEIGHT);
    const scrollY = scrollPercent * (document.documentElement.scrollHeight - window.innerHeight);
    window.scrollTo(0, scrollY);
    this.lastScrollY = scrollY;
    this.updateTextContent(scrollY);

    this.touchMoveY = null;
  }

  private handleTouchEnd(ev: TouchEvent) {
    this.touchStartY = null;
    this.touchStartElTop = null;
    this.touchMoveY = null;
    window.scrollTo(0, this.lastScrollY);
    this.animate('indicator-touch-in', '0.2s');
    this.startAutohideTimer();
  }

  private startAutohideTimer() {
    this.cancelAutohideTimer();
    this.scrollIndicatorAutohideTimer = setTimeout(() => {
      this.shown = false;
      this.animate('indicator-disappear', '0.3s');
    }, HIDE_TIME);
  }

  private cancelAutohideTimer() {
    clearTimeout(this.scrollIndicatorAutohideTimer);
  }

  private updateTextContent(scrollY: number) {
    const textContent = this.getTextContent(scrollY);
    if (!textContent) {
      return;
    }
    this.el.textContent = textContent;
  }

  private animate(name: AnimationName, duration: string) {
    this.el.style.animationName = name;
    this.el.style.animationDuration = duration;
  }
}
