/**
 * Sets/gets KmzData object into IndexedDB. If IndexedDB fails for any reason,
 * it falls back to localStorage.
 */

import * as idb from 'idb-keyval';
import { KmzData } from './kmz-data';

const DB_KEY = 'kmz-data-0';

export async function set(kmzData: KmzData) {
  try {
    await idb.clear();
    await idb.set(DB_KEY, kmzData);
  } catch (e) {
    console.error(e);
    localStorage.setItem(DB_KEY, JSON.stringify(kmzData));
  }
}

export async function get(): Promise<KmzData|undefined> {
  let data: KmzData|undefined;
  try {
    data = (await idb.get(DB_KEY)) as KmzData|undefined;
  } catch (e) {
    console.error(e);
  }
  if (data) {
    return data;
  }
  const json = localStorage.getItem(DB_KEY);
  if (json) {
    return JSON.parse(json) as KmzData;
  }
  return undefined;
}