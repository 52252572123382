/**
 * Custom Event library. Using the term "Avent" to avoid conflicting with the DOM's Event.
 */

export enum AventType {
  StateUpdate = 'state-update',
  NavigateViews = 'navigate-views',
}

export interface Avent<T> {
  data: T,
  target: AventEmitter<T>,
  type: AventType
}

export type AventListenerFn<T> = (avent: Avent<T>) => void;

export class AventEmitter<T> {
  listeners: Map<AventType, AventListenerFn<T>[]> = new Map();

  protected emit(type: AventType, data: T) {
    const avent: Avent<T> = {
      data,
      target: this,
      type,
    };
    const typedListeners = this.listeners.get(type);
    if (!typedListeners) {
      return;
    }
    for (const fn of typedListeners) {
      fn(avent);
    }
  }

  addAventListener(aventType: AventType, fn: AventListenerFn<T>) {
    if (this.listeners.has(aventType)) {
      this.listeners.get(aventType).push(fn);
    } else {
      this.listeners.set(aventType, [fn]);
    }
  }
}
