import { RouteArray, Coordinate } from "src/util/kmz-data";
import { assert } from "./assert";

export function computeMileMarker(route: RouteArray, coord: Coordinate) {
  const [closestIndex, nextClosestIndex] = findClosestPoints(route, coord);

  const closestMile = getMile(route, closestIndex);
  const closestDist = distanceFromRoutePoint(route, closestIndex, coord);
  const nextClosestMile = getMile(route, nextClosestIndex);
  const nextClosestDist = distanceFromRoutePoint(route, nextClosestIndex, coord);

  // Below we're roughly taking the average of the 2 closest points
  // but weight this average against the distance between each one.
  const totalDist = closestDist + nextClosestDist;
  return (closestMile * (closestDist / totalDist)) +
      (nextClosestMile * (nextClosestDist / totalDist));
}

/**
 * Returns the index of the 2 points along the route that are closest to
 * coordinate. In the order that they are in the route.
 */
export function findClosestPoints(route: RouteArray, coord: Coordinate): [number, number] {
  assert(route);
  assert(coord);
  let closestIndex = 0;
  let closestDist = distanceFromRoutePoint(route, 0, coord);
  let nextClosestIndex = closestIndex;
  for (let i = 3; i < route.length; i += 3) {
    let dist = distanceFromRoutePoint(route, i, coord);
    if (dist < closestDist) {
      nextClosestIndex = closestIndex;
      closestIndex = i;
      closestDist = dist;
    }
  }
  const closestMile = getMile(route, closestIndex);
  const nextClosestMile = getMile(route, nextClosestIndex);
  assert(closestMile || closestMile === 0);
  assert(nextClosestMile || nextClosestMile === 0);

  return [closestIndex, nextClosestIndex];
}

export function getMile(route: RouteArray, index: number): number {
  return route[index + 2];
}

export function distanceFromRoutePoint(route: RouteArray, i: number, coord: Coordinate) {
  return distance(route[i], route[i + 1], coord.lat, coord.lon);
}

export function distanceFromCoords(coord1: Coordinate, coord2: Coordinate): number {
  return distance(coord1.lat, coord1.lon, coord2.lat, coord2.lon);
}

export function distance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const p = 0.017453292519943295;    // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p)/2 +
          c(lat1 * p) * c(lat2 * p) *
          (1 - c((lon2 - lon1) * p))/2;

  const km = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  const miles =  km * 0.62137119224;
  return miles;
}
