import { Avent, AventType } from "../util/avent";
import { assert } from "../util/assert";
import { ViewType, View, ViewAventData } from "./view";
import { isSet } from "../util/types";
import { MenuView } from "./menu_view";
import { State } from "./state";
import { getById, IS_SAFARI } from "../util/dom";
import { RouteView } from "./route_view";
import { Transition } from "./transition";

const MENU_PATH = '/#menu';
const ROUTE_PATH = '/';

export class ViewManager {
  private readonly views: Map<ViewType, View>;
  private transition: Transition|null = null;
  private popstateWasFromCode = false;
  _currentType: ViewType;

  constructor(state: State, requestLocationUpdate: () => void,) {
    this.views = new Map();

    const isMenuOpen = location.hash === '#menu';
    this.views.set(ViewType.MENU,
        new MenuView(state, requestLocationUpdate, getById('menu'), isMenuOpen));
    this.views.set(ViewType.ROUTE,
        new RouteView(state, requestLocationUpdate, getById('route'), !isMenuOpen));

    this._currentType = isMenuOpen ? ViewType.MENU : ViewType.ROUTE;

    for (const view of this.views.values()) {
      view.addAventListener(AventType.NavigateViews,
          (avent: Avent<ViewAventData>) => this.handleNavigateAvent(avent));
    }

    window.addEventListener('popstate', () => this.handlePopstate());

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }

  protected handleNavigateAvent(avent: Avent<ViewAventData>) {
    assert(isSet(avent.data.newView));
    const newViewType = avent.data.newView;
    if (newViewType === this.currentType) {
      return;
    }

    // Set the new URL.
    if (newViewType === ViewType.MENU) {
      history.pushState({ pushedFromRoute: true }, '', MENU_PATH);
    } else {
      assert(newViewType === ViewType.ROUTE);
      if (history.state && history.state.pushedFromRoute) {
        this.popstateWasFromCode = true;
        history.back();
        return; // Returning because back() triggers a popstate.
      } else {
        history.pushState(null, '', ROUTE_PATH);
      }
    }

    // Perform the transition.
    this.navigateTo(avent.data.newView);
  }

  private navigateTo(newViewType: ViewType, immediateTransition = false) {
    let canceledTransition = false;
    if (this.transition) {
      canceledTransition = this.transition.finish();
    }
    if (newViewType === this.currentType) {
      return;
    }
    const previousView = this.current;
    const newView = assert(this.views.get(newViewType));
    this._currentType = newViewType;

    this.transition = new Transition(previousView, newView, immediateTransition || canceledTransition);
  }

  private handlePopstate() {
    // On iOS Safari, the user can go back/forward by swiping from the edge of
    // the screen, and in this case Safari shows the previous page as you swipe.
    // Running an animation in this state looks totally broken.
    const immediateTransition = !this.popstateWasFromCode && IS_SAFARI;
    // Reset this flag.
    this.popstateWasFromCode = false;
    this.navigateTo(this.getViewFromHash(), immediateTransition);
  }

  private getViewFromHash(): ViewType {
    return window.location.hash === '#menu' ? ViewType.MENU : ViewType.ROUTE;
  }

  get current(): View {
    return assert(this.views.get(this.currentType));
  }

  get currentType(): ViewType {
    return this._currentType;
  }
}

function logOnError(fn: () => void) {
  try {
    fn();
  } catch (e: any) {
    console.error(e);
  }
}
