import { ViewType, View } from "./view";
import { getById } from "../util/dom";

function prefersReducedMotion(): boolean {
  return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
}

function disableInteraction() {
  document.body.classList.add('disable-interaction');
}

function enableInteraction() {
  document.body.classList.remove('disable-interaction');
}

/**
 * Performs an animated transition from one view to another.
 *
 * Although the API for this supports any view, the actual animation code only
 * supports animations between Menu and Route view.
 */
export class Transition {
  private running = true;
  private ranDomCalculations = false;
  private newScrollY = 0;
  private menuEl = getById('menu');
  private menuAnimationName: string;

  /**
   * Animates from previousView to newView.
   */
  constructor(private readonly previousView: View,
        private readonly newView: View,
        immediate: boolean) {
    logOnError(() => previousView.deactivate());

    const isShowingMenu = newView.getType() === ViewType.MENU;
    this.menuAnimationName = isShowingMenu ? 'slide-in' : 'slide-out';

    const currentScrollY = window.scrollY;
    logOnError(() => this.newScrollY = newView.reRender());

    if (prefersReducedMotion() || immediate) {
      previousView.hide();
      window.scrollTo(0, this.newScrollY);
      this.running = false;
      return;
    }

    try {
      disableInteraction();

      // Float the menu.
      this.menuEl.classList.add('float');

      const menuScroll = isShowingMenu ? this.newScrollY : window.scrollY;
      this.menuEl.scrollTo(0, menuScroll);

      const routeScroll = isShowingMenu ? window.scrollY : this.newScrollY;
      window.scrollTo(0, routeScroll);

      // Trigger a paint now that the animation is ready.
      this.menuEl.clientWidth;

      this.menuEl.classList.add(this.menuAnimationName);
      this.menuEl.onanimationend = () => this.finish();
      requestAnimationFrame(() => this.maybeRunDomCalculations());
    } catch(e) {
      console.error(e);
      this.finish();
    }
  }

  private maybeRunDomCalculations() {
    if (this.running && !this.ranDomCalculations) {
      this.newView.performDomCalculations();
      this.ranDomCalculations = true;
    }
  }

  /**
   * Synchronously finalize the transition so that a new transition can begin.
   */
  finish(): boolean {
    this.maybeRunDomCalculations();
    if (!this.running) {
      return false;
    }

    enableInteraction();

    this.menuEl.classList.remove('float');
    this.menuEl.classList.remove(this.menuAnimationName);

    this.previousView.hide();

    window.scrollTo(0, this.newScrollY);

    logOnError(() => this.newView.activate());
    this.running = false;
    return true;
  }
}

function logOnError(fn: () => void) {
  try {
    fn();
  } catch (e: any) {
    console.error(e);
  }
}
